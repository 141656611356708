<template>
    <div id="appCapsule">

        <div class="section">
            <div class="splash-page mt-5 mb-5">
                <template v-if="loading">
                    <div class="iconbox mb-3" style="background-color: transparent">
                        <div class="spinner-grow text-primary" role="status"></div>
                    </div>
                    <h2 class="mb-2">Verifying...</h2>
                    <p>
                        <i>Please wait</i>
                    </p>
                </template>
                <template v-else-if="error">
                    <div class="iconbox mb-3" style="background-color: transparent">
                        <ion-icon name="warning-outline" class="text-warning"></ion-icon>
                    </div>
                    <h2 class="mb-2">Oops...</h2>
                    <p>
                        {{message}}
                    </p>
                </template>
                <template v-else>
                    <div class="iconbox mb-3" style="background-color: transparent">
                        <ion-icon name="checkmark-done-circle-outline" class="text-success fw-50"></ion-icon>
                    </div>
                    <h2 class="mb-2">Verified</h2>
                    <p>
                        Your email have successfully been verified.
                    </p>
                </template>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "EmailVerification",
    data(){
        return {
            loading: true,
            error: false,
            message: ''
        }
    },
    methods: {
      async verifyMail(){
          const {userId, emailRef} = this.$route.params;
          let response = await this.$store.dispatch('user/getUser', userId);
          if(response.status){
              response = await this.$store.dispatch('user/getUserProfile', userId);
              if(response.status){
                  if(emailRef === response.data.data.email_ver_ref){
                      await this.$store.dispatch('user/updateUser', {
                          userId, userData: {email_is_verified: true}
                      })
                      await this.$store.dispatch('user/updateUserProfile', {
                          userId, userData: {email_ver_ref: ''}
                      })
                      await this.$store.dispatch('user/initUser', {user_id: userId, profile_id: userId})
                  }else{
                      this.error = true;
                      this.message = "Invalid Token"
                  }
              }else{
                  this.error = true;
                  this.message = "Invalid Token"
              }
          }else{
              this.error = true;
              this.message = "Invalid Token"
          }
          this.loading = false;
      }
    },
    created() {
        this.verifyMail()
    }
}
</script>

<style scoped>

</style>